.react-dropzone-input {
  background-color: #eeeeee;
  border: 2px dashed #939393;
  border-radius: 0.625rem;
  padding: 3.75rem 7.18rem;
  text-align: center;
}

.react-dropzone-input:hover {
  cursor: pointer;
}
