.input-password {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  > input {
    position: relative;
    padding-right: 50px;
  }

  > button {
    position: absolute;
    width: 30px;
    justify-content: flex-end;
    align-items: flex-end;
    right: 10px;
  }
}
