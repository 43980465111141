.containerCard {
  width: 100%;
  border-radius: 8px;
  background-color: #f0f2f8;

  border-left-width: 4px;
  border-left-style: solid;

  display: flex;
  justify-content: space-between;
}
.container-price {
  display: flex;
  align-items: center;
}
