.container-loading {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999999999999;

  h1 {
    font-size: 18px;
    color: var(--color-secondary);
    margin-top: 40px;
    text-align: center;
    padding: 0 40px;
  }
}
