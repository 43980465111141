button {
  width: 100%;
  text-align: left;
  background-color: transparent;
  border: none;
  outline: none !important;
}
button.close,
button.navbar-toggler {
  width: initial;
}
/** Authentication Page */
.container-auth {
  display: flex;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  flex-direction: row;
}

.logo-manager-login {
  width: 400px;
  resize-mode: contain;
}

.login-bg {
  width: 100%;
  height: 100vh;
  min-height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .container-card-login {
    width: 70%;
    max-width: 380px;
  }
}

/** Inputs */
.input-feedback {
  font-size: 14px;
  margin-top: 4px;
  font-weight: 600;
  color: orange;
}
.Toastify__close-button {
  flex: 1;
}

.animated {
  transition: all 1s ease-in-out;
  animation: trans 1s;
}

@keyframes trans {
  from {
    transform: translateX(20%);
  }
  to {
    transform: translateX(0%);
  }
}
#calendar-view {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: none !important;
  overflow-y: hidden;

  @media screen and (max-width: 576px) {
    overflow-x: scroll;
  }
}

.view-agenda {
  border: 1px solid #000;
  overflow-y: scroll !important;
  overflow-x: scroll !important;
  width: 100% !important;
  min-width: 100%;
}

.fc-state-highlight {
  opacity: 0;
  border: none;
  margin-bottom: 1rem !important;
}

.fc-theme-standard .fc-scrollgrid {
  border: none !important;
  padding: 0 !important;
}
.fc-scrollgrid-section-header {
  background: #eee !important;
}
.fc-scrollgrid-sync-inner {
  padding: 0.5rem;

  height: 100%;
  border-left: 1px solid #dee1e1;
}

.fc-scroller-harness {
  border-bottom: 1px solid var(--color-gray-10);
  border-radius: 0px 0px 8px 8px;
}

/* Styling for each event from Schedule */
.fc-time-grid-event.fc-v-event.fc-event {
  border-radius: 4px;
  border: none;
  padding: 5px;
  opacity: 0.65;
  left: 5% !important;
  right: 5% !important;
}

/* Bolds the name of the event and inherits the font size */
.fc-event {
  font-size: inherit !important;
  font-weight: bold !important;
}

/* Remove the header border from Schedule */
.fc td,
.fc th {
  border-style: none !important;
  border-width: 1px !important;
  padding: 0 !important;
  vertical-align: top !important;
}

/* Inherits background for each event from Schedule. */
.fc-event .fc-bg {
  z-index: 1 !important;
  background: inherit !important;
  opacity: 0.25 !important;
}

/* Normal font weight for the time in each event */
.fc-time-grid-event .fc-time {
  font-weight: normal !important;
}
.fc-timegrid-slot {
  font-size: 14px !important;
}

/* Apply same opacity to all day events */
.fc-ltr .fc-h-event.fc-not-end,
.fc-rtl .fc-h-event.fc-not-start {
  opacity: 0.65 !important;
  margin-left: 25px !important;
  padding: 5px !important;
}

/* Apply same opacity to all day events */
.fc-day-grid-event.fc-h-event.fc-event.fc-not-start.fc-end {
  opacity: 0.65 !important;
  margin-left: 12px !important;
  padding: 5px !important;
}

/* Calendar Header */
.fc-header-toolbar {
  margin: 1.25rem 1rem 0 0;
}

.fc-toolbar-chunk {
  display: flex;
}
.fc-toolbar-title {
  font-size: 1rem !important;
  color: transparent !important;
}

/* Material design button */
.fc-button {
  display: inline-block;
  position: relative;
  cursor: pointer;

  padding: 0.6em 1.5em !important;

  @media screen and (min-width: 961px) {
    min-height: 36px;
    min-width: 88px;
    padding: 0 6px;
  }

  line-height: 36px;
  vertical-align: middle;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: center;
  border-radius: 4px;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  border: 0;
  margin: 6px 8px;
  letter-spacing: 0.01em;
  background: transparent;
  color: currentColor;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  font-style: inherit;
  font-variant: inherit;
  font-family: inherit;
  text-decoration: none;
  overflow: hidden;
  -webkit-transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.fc-button-primary {
  background-color: var(--color-secondary) !important;
  border: none !important;
  box-shadow: none !important;
  outline: 0 !important;
}

.fc-button:hover {
  background-color: rgba(158, 158, 158, 0.2);
}

.fc-button:focus,
.fc-button:hover {
  text-decoration: none;
}

/* The active button box is ugly so the active button will have the same appearance of the hover */
.fc-state-active {
  background-color: rgba(158, 158, 158, 0.2);
}
.fc-today-button {
  padding: 0 2.5rem !important;
}

/* Not raised button */
.fc-state-default {
  box-shadow: None;
}

.fc-col-header-cell-cushion {
  font-size: 14px;
  padding: 0 !important;
  color: var(--color-black);
  color: #414d55 !important;
}

.fc-timegrid-slots tr {
  height: 45px;
  border-bottom: 1px dashed #a2c0d4;
  display: flex;
  align-items: center;
}
.fc-timegrid-slot-label-cushion {
  color: #414d55 !important;
  padding: 0 10px !important;
  margin: 0 !important;
}
.fc .fc-timegrid-axis-cushion,
.fc .fc-timegrid-slot-label-cushion {
  color: var(--color-gray-50);
}

.fc .fc-v-event {
  background: var(--color-secondary-light) !important;
  border: 1px solid var(--color-secondary);
  border-radius: 5px;
  display: flex;
  align-items: center;
  opacity: 1 !important;
  height: 100%;
}

.fc .fc-v-event .fc-event-title {
  color: var(--color-secondary) !important;
  font-size: 1.2rem !important;
  font-weight: bold !important;
  font-style: normal !important;
  height: 100%;
}

.fc-event-main {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
}
.fc-media-screen {
  height: 100vh;
}

.rdtPicker{
  min-width: 260px;
}